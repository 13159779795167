<template>
  <div
    id="app"
    class="home-layout"
  >
    <header class="home-layout__header">
      <main-menu
        home
        with-finder
      >
        <template #before-finder>
          <div>
            <h1 class="home-layout__header__title">
              <Lang by-key="home-header-title" />
            </h1>

            <p class="home-layout__header__subtitle">
              <Lang by-key="home-header-subtitle" />
            </p>
          </div>
        </template>
      </main-menu>
    </header>
    <main class="home-layout__content">
      <div>
        <nuxt-page />
      </div>
    </main>

    <Footer />
  </div>
</template>

<script lang="ts">
import MainMenu from '@/core/components/menu/MainMenu.vue'
import Footer from '@/core/components/footer/Footer.vue'

export default defineNuxtComponent({
  components: {
    MainMenu,
    Footer
  },
})
</script>

<style lang="scss" scoped>
.home-layout {
  &__content {
    @apply pt-2 pb-2;
    min-height: 30vh;
  }

  &__header {
    &__title {
      @apply hidden;

      @apply text-4xl;
      @apply text-white;

      @apply text-center;

      @screen lg {
        @apply block;
        @apply pt-12;
        @apply pb-4;

        @apply text-center;

      }
    }

    &__subtitle {
      @apply hidden;

      @apply text-xl;
      @apply text-white;

      @screen lg {
        @apply block;

        @apply pt-6;

        @apply text-center;
      }
    }
  }
  &__footer {
    @apply mt-50p;
  }
}
</style>
